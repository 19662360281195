import { createSlice } from "@reduxjs/toolkit"

export const customerSlice = createSlice({
    name: 'customerSlice',
    initialState: { data: '' },
    reducers: {
        setRowData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setRowData } = customerSlice.actions

export default customerSlice.reducer