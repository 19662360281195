import { createSlice } from "@reduxjs/toolkit"

export const vendorReturnSlice = createSlice({
    name: 'vendorReturnSlice',
    initialState: { data: '' },
    reducers: {
        setRowData: (state, action) => {
            state.data = action.payload
        },
        initRowData: (state, action) => {
            state.data = ''
        }
    }
})

export const { setRowData, initRowData } = vendorReturnSlice.actions

export default vendorReturnSlice.reducer