import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const LazyApp = lazy(() => import("./App"));

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Provider store={store}>
//     <Suspense>
//       <React.StrictMode>
//         <LazyApp />
//         <ToastContainer newestOnTop limit={5} />
//       </React.StrictMode>
//     </Suspense>
//   </Provider>
// );

// reportWebVitals();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense>
      <React.StrictMode>
        <LazyApp />
        <ToastContainer newestOnTop limit={5} />
      </React.StrictMode>
    </Suspense>
  </Provider>
);
