import { createSlice } from "@reduxjs/toolkit"

export const inventorySlice = createSlice({
    name: 'inventoryData',
    initialState: {
        data: '',
        inventorys: []
    },
    reducers: {
        setRowData: (state, action) => {
            state.data = action.payload
        },
        setInventorys: (state, action) => {
            state.inventorys = action.payload
        }
    }
})

export const { setRowData, setInventorys } = inventorySlice.actions

export default inventorySlice.reducer